<template>
  <div
    class="divide-y divide-gray-200 lg:grid grid-cols-12 divide-y-0 divide-x"
  >
    <aside class="col-span-2 sm:col-span-3 print-display">
      <nav class="space-y-1 flex lg:block">
        <router-link
          v-for="item in subNavigation"
          :key="item.name"
          :to="item.href"
          :class="[
            subIsActive(item.href)
              ? 'bg-primary border-secondary text-secondary hover:bg-fuchsia-50 hover:text-secondary'
              : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
          ]"
          class="
            group
            lg:border-l-4 lg:border-b-0
            border-b-4
            px-3
            py-2
            flex
            items-center
            text-sm
            font-medium
          "
          :aria-current="subIsActive(item.href) ? 'page' : undefined"
        >
          <component
            :is="item.icon"
            :class="[
              subIsActive(item.href)
                ? 'text-secondary group-hover:text-secondary'
                : 'text-gray-400 group-hover:text-gray-500',
              'flex-shrink-0 lg:-ml-1 lg:mr-3 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          <span class="truncate hidden sm:block">
            {{ t(item.name) }}
          </span>
        </router-link>
      </nav>
    </aside>
    <div class="col-span-10 sm:col-span-9">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
const subNavigation = [
  {
    name: "Statistic",
    href: "/dashboard/evaluation/statistic",
    icon: CalculatorIcon,
  },
  {
    name: "Actual Target",
    href: "/dashboard/evaluation/actual-target",
    icon: CalculatorIcon,
  },
  {
    name: "Charts",
    href: "/dashboard/evaluation/charts",
    icon: ChartBarIcon,
  },
];
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  Switch,
} from "@headlessui/vue";
import { CalculatorIcon, ChartBarIcon } from "@heroicons/vue/outline";
import { useRoute } from "vue-router";

export default {
  components: {
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    CalculatorIcon,
    ChartBarIcon,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);
    const route = useRoute();
    const subIsActive = (input) => {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return route.path.indexOf(path) === 0; // current path starts with this path string
      });
    };
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return {
      availableToHire,
      privateAccount,
      allowCommenting,
      subNavigation,
      allowMentions,
      subIsActive,
      t,
    };
  },
};
</script>
